@import 'src/assets/styles/variable';

.wrap {
  position: relative;
  .wrapIcon {
    background: $white;
    padding: 0.5rem;
    border-radius: 5px;
    z-index: 100;
    cursor: pointer;
    width: 50px;
    position: relative;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    .badgeIcon {
      position: absolute;
      background: $primary;
      width: 25px;
      height: 25px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      font-weight: 700;
      padding: 10px;
      color: $white;
      top: -9px;
      left: -7px;
    }
  }
  .cartList {
    overflow: hidden;
    display: none;
    opacity: 0;
    transition: 0.3s all linear;
    position: absolute;
    padding: 1rem;
    width: 415px;
    font-size: 14px;
    border-radius: 5px;
    right: calc(2rem + 35px);
    z-index: 200;
    background: $white;

    top: 0;
    box-shadow: 0 3px 8px $medium-grey;
    @include media-breakpoint-down(xs) {
      width: calc(100vw - 114px);
    }
    .list {
      max-height: 555px;
      overflow: auto;
      .cartItem {
        color: $black;
        &:not(:last-child) {
          border-bottom: solid 1px $medium-grey;
        }
        padding: 1rem 0px;
        align-items: center;
        @include media-breakpoint-down(xs) {
          flex-direction: column;
          align-items: center;
        }
        .nftImg {
          width: 100px;
          height: 100px;
          object-fit: cover;
        }
        .info {
          padding: 0px 1rem;
          text-align: left;
          @include media-breakpoint-down(xs) {
            padding: 1rem;
            text-align: center;
          }
        }
        .actions {
          display: flex;
          flex-direction: column;
          align-items: flex-end;
          @include media-breakpoint-down(xs) {
            display: flex;
            flex-direction: row-reverse;
          }
          .closeIcon {
            margin-bottom: 0.5rem;
            margin-left: auto;
            cursor: pointer;
            img {
              width: 20px;
            }
            @include media-breakpoint-down(xs) {
              margin-left: 1rem;
            }
          }
          .subActions {
            .minus {
              background: $primary;
              border-radius: 3px;
              width: 20px;
              height: 20px;
              display: flex;
              align-items: center;
              /* text-align: center; */
              color: $white;
              font-weight: bold;
              text-align: center;
              justify-content: center;
              cursor: pointer;
            }
            .quantity {
              font-weight: bold;
              padding: 0px 1rem;
            }
            .add {
              cursor: pointer;
              background: $primary;
              border-radius: 3px;
              width: 20px;
              height: 20px;
              display: flex;
              align-items: center;
              /* text-align: center; */
              color: $white;
              font-weight: bold;
              text-align: center;
              justify-content: center;
            }
          }
        }
      }
    }
    .checkout {
      color: $black;
      padding-top: 1rem;
      border-top: 1px solid $medium-grey;
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 14px;
      button {
        font-size: 14px;
        height: 35px;
      }
      @include media-breakpoint-down(xs) {
        flex-wrap: wrap;
      }
    }
  }
  .toggleList {
    opacity: 1;
    display: block;
    transition: 0.3s all linear;
  }
}
.emptyCart {
  color: $black;
  padding: 1rem 0px;
}
