@import 'src/assets/styles/variable';

.container {
  width: 100%;
  max-width: 1430px;
  padding: 0 20px;
  margin: 0 auto;
}

.wrapHeader {
  background: $black;
  color: $white;
  justify-content: space-between;
  align-items: center;
  height: 110px;
  padding-top: 4px;
  transition: transform 0.25s ease;
  @include media-breakpoint-down(md) {
    height: 84px;
    position: fixed !important;
    width: 100%;
    z-index: 101;
    padding-top: 0;
  }
  .logo {
    max-width: 261px;

    @include media-breakpoint-down(md) {
      padding-left: 6px;
      height: 50px;
    }

    @include media-breakpoint-down(xs) {
      height: 34px;
      margin-top: 17px;
    }
  }
  .wrapMenus {
    list-style: none;
    display: flex;
    margin: 0px;
    align-items: center;
    padding: 1rem 1rem;
    @include media-breakpoint-down(lg) {
      padding-right: 1rem;
    }
    @include media-breakpoint-down(md) {
      display: none;
    }
    li {
      .link {
        color: $white;
        padding: 0 28px;
        text-transform: uppercase;
        font-family: 'zuume', Arial, Helvetica, sans-serif;
        font-weight: 600;
        font-size: 30px;
        line-height: 33px;
        letter-spacing: 0.03em;
        &:hover {
          color: $primary;
        }
      }
      &:last-child {
        .link {
          border-right: none;
        }
      }
    }

    @include media-breakpoint-up(md) {
      & > li {
        position: relative;

        &:hover {
          .link {
            text-decoration: underline;
            text-decoration-color: $primary;
          }

          .subMenu {
            display: block;
          }
        }
      }
    }
  }

  .rewardActive {
    display: flex;
    align-items: center;
    background: $bold-grey;
    border-radius: 12px;
    padding: 0.25rem 1rem;
    transition: background-color 0.25s ease-in-out;
    cursor: default;
    line-height: 1;
    &:hover {
      background-color: $black;
    }
    img {
      height: 50px;
    }
  }
}

.toggleMenuMobile {
  .wrapMenus {
    @include media-breakpoint-down(md) {
      position: absolute;
      z-index: 9;
      top: 65px;
      left: 0;
      display: flex;
      flex-direction: column;
      width: 100%;
      margin-top: 1rem;
      background-color: #000000;
      li {
        min-height: 30px;
        padding: 0px;
        .link {
          border-right: none;
          padding: 0px;
          text-align: left;
        }
      }
    }
  }
}

.isHidden {
  @include media-breakpoint-down(md) {
    transform: translateY(-100%);
  }
}

.wrapLayout {
  min-height: 100vh;
  background: $grey;
  .wrapBody {
    overflow: auto;
    // min-height: calc(100vh - 80px - 239px);

    @include media-breakpoint-down(md) {
      padding-top: 84px;
    }
  }
}
.menuIcon {
  display: none !important;
  @include media-breakpoint-down(md) {
    display: block !important;
    position: absolute;
    right: 26px;
    top: 32px;
    border-radius: 0;
  }
}

.wrapCart {
  padding: 0 0.5rem;
  z-index: 1000;
}

.subMenu {
  background-color: $black;
  margin: 0;
  padding: 10px 0;
  list-style: none;
  min-width: 200px;

  @include media-breakpoint-up(lg) {
    display: none;
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 10;
    padding: 20px 20px 10px;
  }

  @include media-breakpoint-down(md) {
    padding-left: 28px;
  }

  @include media-breakpoint-down(sm) {
    padding-left: 0;
  }

  li {
    padding: 10px 10px;
    @include media-breakpoint-down(md) {
      padding: 10px 0 !important;
    }

    &:not(:last-child) {
      border-bottom: 1px solid rgba($white, 0.3);
    }
  }
}

.subMenuItem {
  color: $white;
  text-transform: uppercase;
  font-size: 16px !important;
  height: auto !important;

  &:hover {
    color: $primary;
  }
}
