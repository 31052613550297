@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,400;0,500;1,400;1,500&display=swap');
@import 'bootstrap';
@import 'variable';

@font-face {
  font-family: archivo;
  src: url(../fonts/archivoblack-webfont.woff2) format('woff2'),
    url(../fonts/archivoblack-webfont.woff) format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: gotham;
  src: url(../fonts/gotham-bold-webfont.woff2) format('woff2'),
    url(../fonts/gotham-bold-webfont.woff) format('woff');
  font-weight: bold;
  font-style: normal;
}
@font-face {
  font-family: gotham;
  src: url(../fonts/gotham-bolditalic-webfont.woff2) format('woff2'),
    url(../fonts/gotham-bolditalic-webfont.woff) format('woff');
  font-weight: bold;
  font-style: italic;
}
@font-face {
  font-family: gotham;
  src: url(../fonts/gotham-medium-webfont.woff2) format('woff2'),
    url(../fonts/gotham-medium-webfont.woff) format('woff');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: gotham;
  src: url(../fonts/gotham-mediumitalic-webfont.woff2) format('woff2'),
    url(../fonts/gotham-mediumitalic-webfont.woff) format('woff');
  font-weight: normal;
  font-style: italic;
}
@font-face {
  font-family: gotham;
  src: url(../fonts/gothamblack-webfont.woff2) format('woff2'),
    url(../fonts/gothamblack-webfont.woff) format('woff');
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: gotham;
  src: url(../fonts/gothamultra-webfont.woff2) format('woff2'),
    url(../fonts/gothamultra-webfont.woff) format('woff');
  font-weight: 900;
}
@font-face {
  font-family: gotham-book;
  src: url(../fonts/Gotham-Book.ttf) format('woff2');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: zuume;
  src: url(../fonts/zuume-semibold-webfont.woff2) format('woff2'),
    url(../fonts/zuume-semibold-webfont.woff) format('woff');
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: zuume;
  src: url(../fonts/zuume-medium.woff2) format('woff2'),
    url(../fonts/zuume-medium.woff) format('woff');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: zuume;
  src: url(../fonts/zuume-semibolditalic-webfont.woff2) format('woff2'),
    url(../fonts/zuume-semibolditalic-webfont.woff) format('woff');
  font-weight: 800;
  font-style: italic;
}

html,
body {
  font-family: gotham;
  height: 100%;
  width: 100%;
  margin: 0px;
  padding: 0px;
  overflow: auto;
}

html {
  font-size: 16px;
}

.error-message {
  color: $red;
  margin-bottom: 0;
  font-size: px-to-rem(16);
}

button {
  &:hover,
  &:focus,
  &:active {
    outline: none;
  }

  touch-action: manipulation;
}

.wrap-global-loading {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  background: #a345f4;
  z-index: 100;
  .lds-facebook {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
  }
  .lds-facebook div {
    display: inline-block;
    position: absolute;
    left: 8px;
    width: 16px;
    background: #fff;
    animation: lds-facebook 1.2s cubic-bezier(0, 0.5, 0.5, 1) infinite;
  }
  .lds-facebook div:nth-child(1) {
    left: 8px;
    animation-delay: -0.24s;
  }
  .lds-facebook div:nth-child(2) {
    left: 32px;
    animation-delay: -0.12s;
  }
  .lds-facebook div:nth-child(3) {
    left: 56px;
    animation-delay: 0;
  }
  @keyframes lds-facebook {
    0% {
      top: 8px;
      height: 64px;
    }
    50%,
    100% {
      top: 24px;
      height: 32px;
    }
  }
}
.link {
  color: $primary;
}
h1 {
  font-family: zuume;
  font-size: 60px;
  margin-bottom: 0px;
  line-height: 1;
  @media screen and (max-width: 992px) {
    font-size: 40px;
  }
  @media screen and (max-width: 567px) {
    font-size: 30px;
  }
}
h2 {
  font-family: zuume;
  font-size: 40px;
  margin-bottom: 0px;
  line-height: 1;
  @media screen and (max-width: 992px) {
    font-size: 30px;
  }
  @media screen and (max-width: 567px) {
    font-size: 22px;
  }
}
h3 {
  line-height: 1;
  font-family: zuume;
  font-size: 30px;
  margin-bottom: 0px;
  @media screen and (max-width: 992px) {
    font-size: 22px;
  }
  @media screen and (max-width: 567px) {
    font-size: 18px;
  }
}

.zuume-font {
  font-family: zuume;
}

.gotham-font {
  font-family: gotham;
}
.gotham-book-font {
  font-family: gotham-book;
}
.archivo-font {
  font-family: archivo;
}

.Toastify__toast--success {
  background: lighten(black, 10%);
}
