@import 'src/assets/styles/variable';

.img-button {
  background: transparent;
  border: none;
  border-radius: 50%;
  width: 44px;
  height: 44px;
  transition: all 0.3s ease-in-out;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  outline: none;
  &:hover,
  &:focus {
    background: $light-grey;
    outline: none;
  }
  &:active {
    background: darken($light-grey, 10%);
  }

  &:disabled {
    opacity: 0.4;
    background-color: transparent;
  }

  &.clear {
    transition: none;
    width: auto;
    height: auto;
    padding: 0px;
    margin: 0px;
    &:hover,
    &:focus {
      background: transparent;
    }
  }
}
