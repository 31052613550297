@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins/breakpoints';

$primary: #c90606;
$primary_hover: #dd2a19;
$secondary: #212121;
$white: #fff;
$disabled: #c8cacd;
$red: #ee5c5e;
$red_hover: #ee6e6c;
$black: #000000;
$green: #219653;
$grey: #cfd0d3;
$light-grey: #d8d8d8;
$lighter-grey: #f9f9f9;
$light-grey2: #f0eff0;
$medium-grey: #9e9e9e;
$medium-grey2: #b7b9bb;
$medium-grey3: #5a5b5d;
$bold-grey: #333;
$light: #e8ebef;
$input-bg: #f5f7fa;
$warning: #ef9f72;
$origan: #ff8200;
$danger: #ee5c5e;
$light-green: #72d299;
$border-light: #e0e5ec;

@function px-to-rem($px) {
  @return ($px/16) + rem;
}

@function resizeWithDesign($px) {
  @if ($px >= 60) {
    @return ($px/2) + px;
  }
  @if ($px >= 40) {
    @return ($px - 12) + px;
  }
  @if ($px >= 20) {
    @return ($px - 8) + px;
  }
  @return ($px - 4) + px;
}

$h1_font_size: 44px;
$h2_zuume_font_size: 28px;
$h2_font_size: 22px;
$h3_font_size: 18px;
$label_font_size: 16px;
$p_font_size: 12px;

$large: 53.58px;
$medium: 48px;
$small: 44px;

$large-mobile: 47px;
$medium-mobile: 39px;
$small-mobile: 39px;
