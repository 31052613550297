@import 'src/assets/styles/variable';

.wrap {
  display: inline-block;
  cursor: pointer;
}

.bar1,
.bar2,
.bar3 {
  width: 38px;
  height: 4px;
  background-color: #ffffff;
  margin: 6px 0;
  transition: 0.4s;
}

.change {
  .bar1 {
    // -webkit-transform: rotate(-45deg) translate(-9px, 6px);
    transform: rotate(-45deg) translate(-7px, 6px);
  }
  .bar2 {
    opacity: 0;
  }
  .bar3 {
    // -webkit-transform: rotate(45deg) translate(-8px, -8px);
    transform: rotate(45deg) translate(-8px, -8px);
  }
}
