@import 'src/assets/styles/variable';

.button {
  font-size: px-to-rem(20);
  padding: px-to-rem(8);
  border: none;
  height: 55px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.3s ease-in-out;
  outline: none;
  // border-radius: 5px;
  @include media-breakpoint-down(sm) {
    padding: 0px 10px;
  }
  &.medium {
    height: $medium;
    @include media-breakpoint-down(sm) {
      height: $medium-mobile;
      font-size: px-to-rem(12);
    }
  }

  &.large {
    height: $large;
    @include media-breakpoint-down(sm) {
      height: $large-mobile;
      font-size: px-to-rem(14);
    }
  }

  &.small {
    height: $small;
  }

  &.primary {
    background: $primary;
    color: $white;

    &:hover,
    &:focus {
      text-decoration: none;
      outline: none;
      background: $primary_hover;
    }

    &:active {
      background: darken($primary, 10%);
    }

    &:disabled {
      background: $grey;
    }
  }

  &.secondary {
    background: $white;
    color: $medium-grey;
    border: solid 1.5px $medium-grey;
    // border-radius: 5px;
    &:hover,
    &:focus {
      text-decoration: none;
      outline: none;
      background: $black;
    }

    &:active {
      background: darken($secondary, 10%);
    }

    &:disabled {
      background: $grey;
    }
  }
  &.third {
    background: $black;
    color: $white;
    border: solid 1.5px $black;
    &:hover,
    &:focus {
      text-decoration: none;
      outline: none;
      background: $secondary;
    }
  }

  &.warning {
    background: $warning;
    color: $white;

    &:hover,
    &:focus {
      outline: none;
      background: lighten($warning, 10%);
    }

    &:active {
      background: darken($warning, 10%);
    }

    &:disabled {
      background: $disabled;
    }
  }

  &.danger {
    background: $danger;
    color: $white;

    &:hover,
    &:focus {
      outline: none;
      background: lighten($danger, 10%);
    }

    &:active {
      background: darken($danger, 10%);
    }

    &:disabled {
      background: $disabled;
    }
  }

  &.dark {
    background: $black;
    color: #a3a3a3;
    text-decoration: none;

    &:hover,
    &:focus {
      outline: none;
      background: lighten($black, 15%);
    }

    &:active {
      background: lighten($black, 10%);
    }

    &:disabled {
      background: $disabled;
    }
  }

  &.red-text {
    color: #e10708;
  }

  &.edit-button {
    height: auto;
    padding: 0;
    line-height: 1;
    background: none;
    color: #e10708;
    transition: color 0.2s ease;
    font-size: 15px;

    &:hover,
    &:focus {
      outline: none;
      color: lighten(#e10708, 15%);
    }

    &:active {
      color: lighten(#e10708, 10%);
    }
  }

  &.outline {
    background: transparent;
    background-color: $lighter-grey;
    box-sizing: border-box;
  }

  &.transparent {
    color: $grey;
    background: transparent;
    padding: 0 5px;
    margin: 0 -5px;
    &:hover,
    &:focus {
      outline: none;
      background: rgba($light-grey, 0.5);
    }

    &:active {
      background: darken(rgba($light-grey, 0.5), 10%);
    }

    &:disabled {
      background: $disabled;
    }
  }

  &.clear {
    color: $grey;
    background: transparent;
    padding: 0 5px;
    margin: 0 -5px;
  }
}
