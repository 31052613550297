@import 'src/assets/styles/variable';

.container {
  width: 100%;
  max-width: 1400px;
  padding: 0 20px;
  margin: 0 auto;
}

.wrap {
  background: $black;
  color: $white;
  padding: 3rem 0;
  .footerItem {
    text-transform: uppercase;
    @include media-breakpoint-down(md) {
      margin: 1rem;
    }
  }
}

.footerTitle {
  font-family: 'gotham', sans-serif;
  font-weight: 700;
  color: #df0404;
  text-transform: uppercase;
  margin: 0;
  padding: 0;
  font-size: 16px;
  letter-spacing: 0.8px;

  &:not(:last-child) {
    margin-bottom: 20px;
  }
}

.footerLogo {
  justify-content: center;
  @include media-breakpoint-down(xs) {
    justify-content: flex-start !important;
  }
}

.logo {
  max-width: 80%;
  @include media-breakpoint-down(sm) {
    display: block;
    margin: 0 auto;
  }
  @include media-breakpoint-down(xs) {
    margin-left: 0;
  }
}

.footerMenu {
  margin: 0;
  padding: 0;
  list-style: none;
  font-family: 'zuume', sans-serif;
  font-weight: 500;
  letter-spacing: 1.1px;
  font-size: 18px;
  line-height: 1.2em;

  li:not(:last-child) {
    margin-bottom: 20px;
  }

  a {
    color: $white;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
      color: $primary;
    }
  }

  .footerSocialItem:not(:last-child) {
    margin-bottom: 5px;
  }

  .footerSocialLink {
    display: inline-flex;
    align-items: center;
  }

  .socialIcon {
    display: block;
    width: 35px;
    margin-right: 15px;
  }
}
